<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import axios from "axios";
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
    page: {title: "EINSTUDIO-Library-detail",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader,vueDropzone: vue2Dropzone
    },data() {
      return {
        title: "LIBRARY DETAILS",
        items: [{text: "Home",href:"/"}, {text: "Library",href:'#'},{text: ""},{text: ""}],
        baseUrl:test.baseUrl,fileUrl:test.fileUrl,user:[],libraryType:[],uploadedFiles: [],fileindex:0,fileArray:[],id:"",categoryName:"",
        dropzoneOptions: {
          url: "https://httpbin.org/post",
          thumbnailWidth: 200,
          maxFilesize: 25,
          timeout: 180000,
          parallelUploads: 100,
          addRemoveLinks: true,
          acceptedFiles: ".img,.jpg,.png,.gif,.pptx,.pdf,.xlsx,.docx",
          accept: this.checkDupl
        }
      };
      },created:function(){
        this.user = JSON.parse(localStorage.getItem('user'));
        this.id=this.$route.params.id;
        this.categoryName=this.$route.params.name;
        this.loadData();
      },methods: {
          loadData(){
          let loader=this.$loading.show();
          axios.get(this.baseUrl+"/Library/GetAll?cat="+this.id,{
            headers:{Authorization: `Bearer ${this.user.jwt}`}
          }).then((response)=>{
              this.fileArray= response.data;
     
              this.getMyGroups(this.$route.params.id)

              if(this.fileArray.length>0)
              {
                this.title=this.$route.params.name;
                this.items[1].text="Library";
                this.items[2].text=this.fileArray[0].basePath;
                this.categoryName=this.fileArray[0].basePath;
                this.items[1].href='/librarydetails/'+this.$route.params.name;
                this.items[2].href='/librarydetails/'+this.$route.params.name+"/"+this.$route.params.id;

              }
              else
              {
                this.items[1].href='/librarydetails/'+this.$route.params.name;
                this.items[2].text=this.$route.params.name;
              }

              loader.hide();
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
        },addData(){
          let loader = this.$loading.show();
          var formToPost = new FormData(document.getElementById("add-file-form"));

          formToPost.append("name",this.libraryType.name)
          formToPost.append("userId",this.user.id)
          formToPost.append("isInGroup",false)
          formToPost.append("basePath",this.categoryName)
          formToPost.append("subPath",this.libraryType.name)
          formToPost.append("libraryTypeId",this.id)

          
          for (let index = 0; index < this.uploadedFiles.length; index++) {
            const element = this.uploadedFiles[index];
            formToPost.append("files",element)
          }

          axios.post(`${this.baseUrl}/Library/Add`, formToPost, {
            headers: { Authorization: `Bearer ${this.user.jwt}`, "Content-Type": "multipart/form-data"}})
          .then((response) => 
          {
            loader.hide();
            this.uploadedFiles=[];
            this.$swal("",response.data.message, "success");
            this.loadData(this.categoryName);
            this.hideModal('modal-lg');
          }).catch((error) => 
          {
            loader.hide();
            var html="";
            if(error.response.data.length>0)
            {
              for (let index = 0; index < error.response.data.length; index++) 
              {
                html +="<small>"+ error.response.data[index].message+"</small><hr>";
              }
            }
            else
            {
              html ="<p class='small'>"+ error.response.data.message+"</p>";
            }
            this.$swal("",html,"error");
          });
        },getMyGroups(id){
         
          axios.get(this.baseUrl+"/Library/LibraryType/GetOne/"+id,{
            headers:{Authorization: `Bearer ${this.user.jwt}`}
          }).then((response)=>{
              this.libraryType= response.data;
              this.title=this.$route.params.name+" > "+response.data.name;
          })
        },dropzoneLoadedFile(file) {
          this.uploadedFiles.push(file);
        },checkDupl(file, done) {
          let v = this.$refs.FileDropzone;
          let files = v.getAcceptedFiles()
          let item = files.find( el => el.upload.filename === file.name )
          if (item) {
            done("duplicate")
          } else {
            done()
          }
        },fileDelete(fileid){
          let loader= this.$loading.show();
          axios.delete(`${this.baseUrl}/Library/Delete/`+fileid, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            loader.hide();
            this.$swal("",response.data.message, "success");
            this.loadData();
            
          })
          .catch((error) => 
          {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
        },foldDelete(foldid){
          let loader= this.$loading.show();
          axios.delete(`${this.baseUrl}/Library/LibraryType/Delete/`+foldid, { headers: { Authorization: `Bearer ${this.user.jwt}`},
          }).then((response) => 
          {
            loader.hide();
            this.$swal("",response.data.message, "success");
            this.loadData()
          })
          .catch((error) => 
          {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
          });
        },DeleteConfirm(fileid,categoryname,types){
        this.$swal({ 
          title: "WARNING",
          text: "Are you sure? This data will be delete.",
          //type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes",
          cancelButtonText:"No",
          }).then((result) => {
            if (result.value) 
            {
              if(types==1)
              this.fileDelete(fileid,categoryname)
              else if(types==2)
              this.foldDelete(fileid,categoryname)
            }
          });
        },showModal(modalname) {
        this.$bvModal.show(modalname)
        },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
       }
     }
  };
</script>
<style>
.cover-image{
  width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
        <div class="col-lg-12">
          <router-link :to="{ path: '/librarydetails/'+this.$route.params.name}" tag="a" class="btn btn-sm btn-info"><i class="fa fa-caret-left"></i> RETURN BACK</router-link>
          <a type="button" id="add-modal" v-if="user.rol=='admin' | user.rol=='teacher'"  v-b-modal.modal-lg  class="btn btn-sm btn-success ml-2" @click="getMyGroups($route.params.id)"><i class="fa fa-folder"></i> + ADD NEW FILES</a>
        </div>
    </div>
    <div id="files-div" class="row">
      <div class="col-lg-3 col-xs-3 col-md-6 col-sm-12" v-for="(item, index) in fileArray" :key="index">
            <div class="card">
                    <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-1 overflow-hidden" style="width:100%">
                          
                             <p><i class="fa fa-file"> </i> {{item.explanation}} </p>
               
                            <img v-if="(item.fileExtension=='.jpg'| item.fileExtension=='.png' | item.fileExtension=='.JPG' | item.fileExtension=='.PNG')" :src="fileUrl+'/files/images/library/'+item.basePath+'/'+item.subPath+'/'+item.name" class="cover-image" />
                            <img v-if="(item.fileExtension=='.pdf')" :src="fileUrl+'/files/images/pdf.png'" class="cover-image" />
                            <img v-if="(item.fileExtension=='.xlsx')" :src="fileUrl+'/files/images/excel.png'" class="cover-image" />
                            <img v-if="(item.fileExtension=='.docx')" :src="fileUrl+'/files/images/word.png'" class="cover-image" />
                            <img v-if="(item.fileExtension=='.link')" :src="fileUrl+'/files/images/link.png'" class="cover-image" />
                            <img v-if="(item.fileExtension=='.video')" :src="fileUrl+'/files/images/video.png'" class="cover-image" />
                            <img v-if="(item.fileExtension=='.pptx')" :src="fileUrl+'/files/images/ppt.png'" class="cover-image" />
                        </div>
                    </div>
                </div>
                <div class="card-body border-top py-3">
                    <div class="text-truncate">
                        <a v-b-tooltip.hover title="Open"  class="btn btn-sm btn-info font-size-11 mr-1" :href="(item.fileExtension=='.link' || item.fileExtension=='.video')? (item.fileExtension=='.link'?item.libraryLink:item.libraryVideoLink): 
                        (fileUrl+'/files/images/library/'+item.basePath+'/'+item.subPath+'/'+item.name)" target="_blank"><i class="fa fa-eye"> </i></a>
                        <a v-b-tooltip.hover title="Delete" type="button" v-if="user.rol=='admin' | (user.id==item.userId)"   class="btn btn-sm btn-danger font-size-11" @click="DeleteConfirm(item.id,item.subPath,1)"><i class="fa fa-trash"></i></a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <b-modal id="modal-lg" size="lg" :title="'New Files '+categoryName+'-'+libraryType.name" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-file-form">
            <div class="row">
              <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                <div class="form-group">
                  <label id="tooltip-link"><i class="fa fa-info-circle"></i> Link</label>
                  <b-tooltip target="tooltip-link" triggers="hover">
                    <img src="@/assets/images/link-example.jpg" style="width:100%" />
                    <p>
                      Copy the place in the browser link section.
                    </p>
                  </b-tooltip>
                    <input type="text" class="form-control" name="libraryLink" placeholder="may be empty" autocomplete="off"/>
                </div>
              </div>
              <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
                <div class="form-group">
                  <label id="tooltip-link-video"><i class="fa fa-info-circle"></i> Video Link</label>
                  <b-tooltip target="tooltip-link-video" triggers="hover">
                    <img src="@/assets/images/link-example.jpg" style="width:100%" />
                    <p>
                      Copy the place in the browser link section.
                    </p>
                  </b-tooltip>
                    <input type="text" class="form-control" name="libraryVideoLink" placeholder="may be empty" autocomplete="off"/>
                </div>
              </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" class="form-control" required></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE FILES</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>